<template>
  <tr class="add-row">
    <td>
      <div class="add-row-buttons">
        <div class="main-button orange no-background sm" @click="save">
          Save
        </div>
        <div class="main-button no-background sm" @click="cancel">Cancel</div>
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('link_status'))">
        <input-text v-model="link_status" />
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('group'))">
        <input-select v-model="group" />
      </div>
    </td>
  </tr>
</template>

<script>
import InputSelect from '@/components/inputs/InputSelect'
import { newRowMixin } from '@/utils/new-row-mixin'
import { fetchData, urls } from '@/utils/urls.js'

export default {
  name: 'NewRowLinkStatus',
  mixins: [newRowMixin],
  components: { InputSelect },

  data() {
    return {
      link_status: {
        value: '',
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },
      group: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [], // Данные для выпадающего списка { id: 1, name: 'id 1 text text text' }
        class: 'sm',
      },
    }
  },

  created() {
    this.fetchGroups()
    for (let key in this.rowData) {
      const element = this.rowData[key]
      if (key in this.$data) {
        if (key === 'group') this.$data[key].value = [element]
        else this.$data[key].value = element

        if (element || element === 0 || element === false)
          this.$data[key].isValid = true
      }
    }
  },

  computed: {
    isValid() {
      if (!this.link_status.isValid) return false

      return true
    },
  },

  methods: {
    async fetchGroups() {
      let result = await fetchData(
        urls.linkStatusesGroups,
        'GET',
        null,
        'page=1&per_page=all&group=asc'
      )
      if (!result?.detail) {
        this.group.dataList = (result?.rows || []).map((item) => {
          return {
            id: item.group,
            name: item.group,
          }
        })
      } else {
        console.error(result.detail)
      }
    },

    cancel() {
      this.$emit('cancel')
    },

    save() {
      if (!this.isValid) return

      this.$emit('save', this.rowForSave())
    },
  },
}
</script>
